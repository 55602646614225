import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetBookingById from "../components/hooks/useGetBookingById";
import useGetBikeById from "../components/hooks/useGetBikeById";
import useGetCompanyById from "../components/hooks/useGetCompanyById";
import useGetUserById from "../components/hooks/useGetUserById";
import LoadingSpinner from "../components/LoadingSpinner";
import BookingInfo from "../components/BookingInfo";
import QRModal from "../components/QRModal";
import { isMobile } from "react-device-detect";
import googleplay from "../assets/googleplay.svg";
import appstore from "../assets/appstore.svg";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import axios from "axios";
import "../assets/fonts/fonts.css";
import "../styles/style.css";
import { db, functions } from "../config/firebase";
import StatusPage from "../components/StatusPage";
import { Link } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

const BookingPage = () => {
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const { bookingId } = useParams();
  const { booking, error: bookingError } = useGetBookingById(bookingId);

  const bikeID = booking ? booking.bikeID : null;
  const riderID = booking ? booking.riderID : null;
  const companyID = booking ? booking.companyID : null;

  const { bike } = useGetBikeById(bikeID);
  const { user } = useGetUserById(riderID);
  const { company } = useGetCompanyById(companyID);

  const [isVisible, setIsVisible] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(
    booking ? booking.status : null
  );
  const [cancelReason, setCancelReason] = useState(
    booking ? booking.cancelReason : null
  );

  useEffect(() => {
    if (booking) {
      setBookingStatus(booking.status);
      setCancelReason(booking.cancelReason);
    }
  }, [booking]);

  // useEffect(() => {
  //   if (bookingStatus === "pending") {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // }, [bookingStatus]);

  const handleClick = (url) => {
    if (isMobile) {
      window.location.href = url;
    } else {
      setQrValue(url);
      setIsQRModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsQRModalOpen(false);
  };

  if (!bookingId || bookingError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen text-center text-lg">
        <div className="text-2xl mb-4 font-black">😔 Booking declined</div>
        <p>📅 Download the Flexbike mobile app to</p>
        <p className="font-black pb-4">get more bookings</p>
        <div className="flex items-center justify-between space-x-4 mb-4">
          <div
            className="cursor-pointer w-32 h-auto"
            onClick={() =>
              handleClick(
                "https://play.google.com/store/apps/details?id=com.flexbike&hl=en"
              )
            }
          >
            <div
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={googleplay}
                alt="Google Play"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div
            className="cursor-pointer w-32 h-auto"
            onClick={() =>
              handleClick("https://apps.apple.com/gb/app/flexbike/id6449488641")
            }
          >
            <div
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={appstore}
                alt="App Store"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
        <QRModal
          isOpen={isQRModalOpen}
          qrValue={qrValue}
          onRequestClose={handleCloseModal}
        />
      </div>
    );
  }

  if (!booking || !bike || !user || !company) {
    return <LoadingSpinner />;
  }
  // if (bookingError || bikeError || userError || companyError) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       {bookingError?.message ||
  //         bikeError?.message ||
  //         userError?.message ||
  //         companyError?.message}
  //     </div>
  //   );
  // }

  const startDate = new Date(booking.start.seconds * 1000).toLocaleDateString(
    "en-GB",
    {
      day: "numeric",
      month: "long",
    }
  );

  const endDate = booking.end
    ? new Date(booking.end.seconds * 1000).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
      })
    : null;

  const displayDate = () => {
    if (booking.monthlySub) {
      return (
        <>
          Long-term rental.
          <br />
          Start date: {startDate}.
        </>
      );
    } else {
      return `${startDate} - ${endDate}`;
    }
  };

  const handleConfirm = async () => {
    try {
      const bookingRef = doc(db, "bookings", bookingId);
      await updateDoc(bookingRef, {
        status: "confirmed",
        responded: serverTimestamp(),
      });
      const chargeOneOff = httpsCallable(functions, "chargeOneOff");
      await chargeOneOff({ paymentIntentId: booking.paymentIntentId });
      console.log("Booking confirmed!");
      setBookingStatus("confirmed");
      if (booking.marketplaceBooking) {
        axios
          .post(`https://app.nativenotify.com/api/indie/notification`, {
            subID: `${riderID}`,
            appId: 8514,
            appToken: "qrC2RTaC5ozt17tNrI1mYi",
            title: "🥳 Booking confirmed",
            message: booking.monthlySub
              ? `Booking of ${bike.name} starting on ${startDate} has been confirmed`
              : `Booking of ${bike.name} for ${displayDate(
                  booking
                )} has been confirmed`,
          })
          .catch((error) => {
            console.error("Failed to send notification to rider", error);
            alert(`Failed to send notification to rider ${error}`);
          });
      }
    } catch (error) {
      console.error("Error while confirming booking:", error);
    }
  };

  const handleDecline = async () => {
    try {
      const bookingRef = doc(db, "bookings", bookingId);
      await updateDoc(bookingRef, {
        status: "declined",
        responded: serverTimestamp(),
      });
      const refundCharge = httpsCallable(functions, "refundCharge");
      await refundCharge({ paymentIntentId: booking.paymentIntentId });
      console.log("Booking rejected and deleted!");
      setBookingStatus("declined");

      if (booking.marketplaceBooking) {
        axios
          .post(`https://app.nativenotify.com/api/indie/notification`, {
            subID: `${riderID}`,
            appId: 8514,
            appToken: "qrC2RTaC5ozt17tNrI1mYi",
            title: "❌ Booking rejected",
            message: booking.monthlySub
              ? `The host was unable to confirm your booking for ${
                  bike.name
                } starting on ${displayDate(
                  booking
                )}. Tap here to book a new bike!`
              : `The host was unable to confirm your booking for ${
                  bike.name
                } for ${displayDate(booking)}. Tap here to book a new bike!`,
          })
          .catch((error) => {
            console.error("Failed to send notification to host", error);
            alert(`Failed to send notification to host ${error}`);
          });
      }
    } catch (error) {
      console.error("Error while rejecting booking:", error);
    }
  };

  const renderBookingDetailsButton = () => (
    <div className="mt-10 mb-6 flex justify-center">
      <button
        className="myButton items-center justify-center text-black px-6 py-2 rounded-2xl w-64 shadow-md"
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? "Hide booking details" : "Show booking details"}
      </button>
    </div>
  );

  const renderBookingInfo = () => (
    <div
      className={`transition-max-height duration-1000 overflow-hidden ${
        isVisible ? "max-h-[1000px]" : "max-h-0"
      }`}
    >
      <div className="mx-auto sm:w-full md:w-96 lg:w-96 xl:w-96">
        <BookingInfo
          bike={bike}
          booking={booking}
          user={user}
          company={company}
          handleDecline={handleDecline}
          handleConfirm={handleConfirm}
          startDate={startDate}
          displayDate={displayDate(booking)}
          bookingStatus={bookingStatus}
        />
      </div>
    </div>
  );

  return (
    <div className="mx-auto overflow-y-auto overflow-x-hidden items-center justify-center max-w-screen-lg px-4 py-10">
      {["confirmed", "cancelled", "declined", "expired"].includes(
        bookingStatus
      ) && (
        <div>
          <StatusPage
            companyId={companyID}
            status={bookingStatus}
            cancelReason={cancelReason}
          />
          {bookingStatus === "confirmed" ? renderBookingDetailsButton() : null}
          {bookingStatus === "confirmed" ? renderBookingInfo() : null}
        </div>
      )}
      {bookingStatus === "pending" && (
        <div className="flex justify-center items-center">
          <div className="mx-auto w-96">
            <div className="text-center text-black font-bold text-2xl">
              🙌 New booking request!
            </div>
            <BookingInfo
              bike={bike}
              booking={booking}
              user={user}
              company={company}
              handleDecline={handleDecline}
              handleConfirm={handleConfirm}
              startDate={startDate}
              displayDate={displayDate(booking)}
              bookingStatus={bookingStatus}
            />
          </div>
        </div>
      )}
      <div className="mt-7 text-center mx-auto text-gray-400 flex justify-center">
        Flexbike is an app that connects vehicle rental shops with customers
      </div>
      {/*
      <div className="mx-auto flex justify-center">
        <Link to="/hostguide" className="text-gray-400 underline">
          Learn more
        </Link>
      </div>
      */}
    </div>
  );
};

export default BookingPage;
