import Icon from "@mdi/react";
import { mdiWhatsapp } from "@mdi/js";
import React, { useState, useEffect } from "react";
import imgBadgeAppStore from "../assets/appstore.svg";
import imgBadgeGooglePlay from "../assets/googleplay.svg";

function formatPrice(price) {
  return new Intl.NumberFormat("id-ID").format(price);
}

const BookingInfo = ({
  bike,
  booking,
  user,
  company,
  handleDecline,
  handleConfirm,
  startDate,
  displayDate,
  bookingStatus,
}) => {
  // Function to create a Google Maps URL
  const createMapLink = (address) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
  const [timer, setTimer] = useState(null);

  // New function to open the app
  const openApp = () => {
    window.location.href = "https://flexbike.app/download";
  };

  useEffect(() => {
    if (booking && booking.bookingMade) {
      // Преобразуем Timestamp в Date
      const bookingMadeDate = booking.bookingMade.toDate();
      console.log(bookingMadeDate);
      const deadline = new Date(
        bookingMadeDate.getTime() + 24 * 60 * 60 * 1000
      );
      console.log(deadline);

      const interval = setInterval(() => {
        const now = new Date();
        let timeLeft = deadline - now;

        if (timeLeft <= 0) {
          clearInterval(interval);
          setTimer("00:00:00");
        } else {
          let hours = Math.floor(timeLeft / (1000 * 60 * 60));
          let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          hours = hours < 10 ? `0${hours}` : hours;
          minutes = minutes < 10 ? `0${minutes}` : minutes;
          seconds = seconds < 10 ? `0${seconds}` : seconds;

          setTimer(`${hours}:${minutes}:${seconds}`);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [booking]);

  console.log(timer);

  console.log(booking.paymentIntentId);

  return (
    <div>
      <div className="text-left pt-4">
        {bookingStatus === "pending" ? (
          <div>
            {timer && (
              <div className="mb-5 font-semibold text-center mx-auto text-gray-500 flex justify-center">
                Expires in {timer}
              </div>
            )}
          </div>
        ) : null}
        <h2 className="font-bold text-black text-lg">🛵 Booking</h2>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Bike: </span> {bike.name}
          {bike.displacement ? " " + bike.displacement + "cc" : null}
          {bike.year ? " " + bike.year : null}
        </div>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Features: </span>
          {bike.surfRack && "Surf rack"}
          {bike.keyless && (bike.surfRack ? ", Keyless" : "Keyless")}
          {bike.ABS && (bike.surfRack || bike.keyless ? ", ABS" : "ABS")}
        </div>

        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Dates: </span>
          {displayDate}
        </div>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Helmets: </span>
          {booking.numHelmets}
        </div>
      </div>

      <div className="text-left pt-4">
        <h2 className="font-bold text-black text-lg">🧑 Rider</h2>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Name: </span>
          {user.name}
        </div>
      </div>

      <div className="text-left pt-4">
        <h2 className="font-bold text-black text-lg">💸 Payment</h2>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Rental price: </span>
          {formatPrice(booking.price)} IDR
          {booking.monthlySub ? " per month" : null}
        </div>
        <div className="text-gray-500">
          <span className="text-gray-700 font-bold">Commission (15%): -</span>
          {formatPrice(booking.price * 0.15)} IDR
          {booking.monthlySub ? " per month" : null}
        </div>
        {booking.deposit > 1000 ? (
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Security deposit: </span>
            {formatPrice(booking.deposit)} IDR
          </div>
        ) : null}
        {booking.deliveryCharge ? (
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Delivery fee: </span>
            {formatPrice(booking.deliveryCharge)} IDR
          </div>
        ) : null}
        <div className="text-gray-500 underline">
          <span className="text-gray-700 font-bold">Total payout: </span>
          {formatPrice(booking.price * 0.85 + booking.deliveryCharge)} IDR
          {booking.monthlySub ? " per month" : null}
        </div>
        {booking.paymentIntentId ? (
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Payment method: </span>
            {booking.directPayment ? "Cash on delivery" : "Card"}
          </div>
        ) : (
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Payment method: </span>
            Cash
          </div>
        )}
      </div>
      {booking.requestedDelivery ? (
        <div className="text-left pt-4">
          <h2 className="font-bold text-black text-lg">➡️ Delivery</h2>
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Delivery address: </span>
            <a
              href={createMapLink(booking.deliveryAddress)}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {booking.deliveryAddress}
            </a>
          </div>
          {booking.deliveryTime ? (
            <div className="text-gray-500">
              <span className="text-gray-700 font-bold">Delivery time: </span>
              {booking.deliveryTime} on {startDate}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="text-left pt-4">
          <h2 className="font-bold text-black text-lg">
            🫴️ Customer will collect bike from your shop
          </h2>
          <div className="text-gray-500">
            <span className="text-gray-700 font-bold">Shop address: </span>
            <a
              href={createMapLink(company.companyAddress)}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {company.companyAddress}
            </a>
          </div>
          {booking.collectionTime ? (
            <div className="text-gray-500">
              <span className="text-gray-700 font-bold">Collection time: </span>
              {booking.collectionTime} on {startDate}
            </div>
          ) : null}
        </div>
      )}
      {bookingStatus === "pending" && (
        <>
          <div className="font-bold mt-7 text-center mx-auto text-gray-700 flex justify-center">
            Press accept or decline
          </div>
          <div className="flex justify-center mt-2 space-x-6">
            <button
              className="confirm-button w-40 px-4 py-4 bg-green-500 text-white text-center rounded-xl hover:bg-green-600 focus:ring focus:ring-green-200 focus:outline-none transition shadow-md font-bold"
              onClick={handleConfirm}
            >
              Accept
            </button>
            <button
              className="confirm-button w-40 px-4 py-4 bg-red-400 text-white text-center rounded-xl hover:bg-red-500 focus:ring focus:ring-red-200 focus:outline-none transition shadow-md font-bold"
              onClick={handleDecline}
            >
              Decline
            </button>
          </div>
          <div>
            {timer && (
              <div className="mt-9 text-center">
                <div className="font-bold mt-7 text-center mx-auto text-gray-700 flex justify-center">
                  ⌛ Request expires in
                </div>
                <div className="text-2xl text-gray-700 font-bold p-2 border border-gray-300 rounded-lg mt-2">
                  {timer}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BookingInfo;

